/* tslint:disable */
/* eslint-disable */
/**
 * SteadyMD Partner API
 * The Consult API allows you to find availability for Clinicians in your programs, create scheduled or on-demand Consults, and view the status of Consults in the SteadyMD Clinic.
 *
 * The version of the OpenAPI document: 1.1.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios";
// @ts-ignore
import { BaseAPI, BASE_PATH, RequestArgs } from "./base";
// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, createRequestFunction, DUMMY_BASE_URL, serializeDataIfNeeded, setBasicAuthToObject, setSearchParams, toPathString } from "./common";
import { Configuration } from "./configuration";

/**
 *
 * @export
 * @interface Annotation
 */
export interface Annotation {
    /**
     * For TextExpander, use actual snippet. Ex: .cslUTITreat OR .cslSmokingNote
     * @type {string}
     * @memberof Annotation
     */
    displayedText: string;
    /**
     * Short description shown as a tooltip when hovering over the annotation. Ex: \"Patient open to suggestions\"
     * @type {string}
     * @memberof Annotation
     */
    description?: string | null;
    /**
     * Change the annotation color with a valid hex code. Default black is #404040.
     * @type {string}
     * @memberof Annotation
     */
    color?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Annotation
     */
    components?: Array<AnnotationComponentsEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum AnnotationComponentsEnum {
    Messages = "messages",
    Assessment = "assessment",
    TreatmentPlan = "treatment_plan",
    ReferOutAssessment = "refer_out.assessment",
    ReferOutTreatmentPlan = "refer_out.treatment_plan",
    ExternalConsultTextExpanderSnippets = "external.consult.text_expander_snippets",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface AnnotationBundle
 */
export interface AnnotationBundle {
    /**
     * Annotation bundle and treatment type. Ex: \"TextExpander: UTI\"
     * @type {string}
     * @memberof AnnotationBundle
     */
    name: string;
    /**
     *
     * @type {Array<Annotation>}
     * @memberof AnnotationBundle
     */
    annotations: Array<Annotation>;
}
/**
 *
 * @export
 * @interface Clinician
 */
export interface Clinician {
    /**
     *
     * @type {string}
     * @memberof Clinician
     */
    url: string;
    /**
     *
     * @type {string}
     * @memberof Clinician
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof Clinician
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof Clinician
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof Clinician
     */
    guid: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Clinician
     */
    licensedIn: Array<ClinicianLicensedInEnum>;
    /**
     *
     * @type {string}
     * @memberof Clinician
     */
    dosespotId?: string | null;
    /**
     *
     * @type {string}
     * @memberof Clinician
     */
    npiNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof Clinician
     */
    headshot?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ClinicianLicensedInEnum {
    Al = "AL",
    Ak = "AK",
    As = "AS",
    Az = "AZ",
    Ar = "AR",
    Aa = "AA",
    Ae = "AE",
    Ap = "AP",
    Ca = "CA",
    Co = "CO",
    Ct = "CT",
    De = "DE",
    Dc = "DC",
    Fl = "FL",
    Ga = "GA",
    Gu = "GU",
    Hi = "HI",
    Id = "ID",
    Il = "IL",
    In = "IN",
    Ia = "IA",
    Ks = "KS",
    Ky = "KY",
    La = "LA",
    Me = "ME",
    Md = "MD",
    Ma = "MA",
    Mi = "MI",
    Mn = "MN",
    Ms = "MS",
    Mo = "MO",
    Mt = "MT",
    Ne = "NE",
    Nv = "NV",
    Nh = "NH",
    Nj = "NJ",
    Nm = "NM",
    Ny = "NY",
    Nc = "NC",
    Nd = "ND",
    Mp = "MP",
    Oh = "OH",
    Ok = "OK",
    Or = "OR",
    Pa = "PA",
    Pr = "PR",
    Ri = "RI",
    Sc = "SC",
    Sd = "SD",
    Tn = "TN",
    Tx = "TX",
    Ut = "UT",
    Vt = "VT",
    Vi = "VI",
    Va = "VA",
    Wa = "WA",
    Wv = "WV",
    Wi = "WI",
    Wy = "WY",
}

/**
 *
 * @export
 * @interface ClinicianPortalUX
 */
export interface ClinicianPortalUX {
    /**
     *
     * @type {Array<AnnotationBundle>}
     * @memberof ClinicianPortalUX
     */
    annotationBundles: Array<AnnotationBundle>;
}
/**
 *
 * @export
 * @interface ConnectPhoneCallRequest
 */
export interface ConnectPhoneCallRequest {
    /**
     *
     * @type {string}
     * @memberof ConnectPhoneCallRequest
     */
    fromNumber: string;
    /**
     *
     * @type {string}
     * @memberof ConnectPhoneCallRequest
     */
    toNumber: string;
    /**
     *
     * @type {string}
     * @memberof ConnectPhoneCallRequest
     */
    consultGuid: string;
    /**
     *
     * @type {string}
     * @memberof ConnectPhoneCallRequest
     */
    participantIdentity: string;
}
/**
 *
 * @export
 * @interface Consult
 */
export interface Consult {
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    url: string;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    createdAt: string;
    /**
     *
     * @type {number}
     * @memberof Consult
     */
    sla: number;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    typicalDuration: string;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    consultType: string;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    interactionMode: ConsultInteractionModeEnum;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    careOption: ConsultCareOptionEnum;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    visitStatus: ConsultVisitStatusEnum;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    usState: ConsultUsStateEnum;
    /**
     *
     * @type {Program}
     * @memberof Consult
     */
    program: Program;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    intakeUri: string;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    episodeUri: string;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    episodeGuid: string;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    isSyncInteraction: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    isAsyncInteraction: boolean;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    statusSetAt: string;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    statusValidUntil: string | null;
    /**
     *
     * @type {Array<Diagnosis>}
     * @memberof Consult
     */
    availableDiagnoses: Array<Diagnosis>;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    dueDate?: string | null;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    completionDueDate?: string | null;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    clinicianGuid: string | null;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    clinicianName: string;
    /**
     * The link to the EMR. steadymd://episode/-guid- or steadymd://episode/-guid-/lab/requisition/-guid- or a valid URL for a 3rd Party EMR.
     * @type {string}
     * @memberof Consult
     */
    emrUri: string;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    isExternal: boolean;
    /**
     * External consults can send links
     * @type {{ [key: string]: any; }}
     * @memberof Consult
     */
    externalLinks?: { [key: string]: any };
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    reason: string | null;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    appointmentStartsAt: string | null;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    appointmentEndsAt: string | null;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    appointmentScheduledStartsAt: string | null;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    appointmentScheduledEndsAt: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    isScheduled: boolean;
    /**
     *
     * @type {string}
     * @memberof Consult
     */
    clinicianInstructions: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    isPediatric?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    isMessageFollowup: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    isPauseAllowed: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    patientContacted: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    isHighPriority?: boolean;
    /**
     *
     * @type {ClinicianPortalUX}
     * @memberof Consult
     */
    clinicianPortalUx: ClinicianPortalUX;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    isManualRxEnabled: boolean;
    /**
     *
     * @type {Array<ConsultTags>}
     * @memberof Consult
     */
    tags: Array<ConsultTags>;
    /**
     * The reason for the consult.
     * @type {string}
     * @memberof Consult
     */
    reasonForVisit?: ConsultReasonForVisitEnum;
    /**
     *
     * @type {boolean}
     * @memberof Consult
     */
    hasMaxConcurrentConsults: boolean;
    /**
     *
     * @type {Array<LimitedIntegrationComments>}
     * @memberof Consult
     */
    limitedIntegrationComments: Array<LimitedIntegrationComments>;
}

/**
 * @export
 * @enum {string}
 */
export enum ConsultInteractionModeEnum {
    AutoTriage = "auto_triage",
    ChartReview = "chart_review",
    Message = "message",
    PhoneCall = "phone_call",
    Chat = "chat",
    VideoChat = "video_chat",
}
/**
 * @export
 * @enum {string}
 */
export enum ConsultCareOptionEnum {
    GeneralVisit = "general_visit",
    GeneticCounselorLabResults = "genetic_counselor_lab_results",
    LabApproval = "lab_approval",
    LabOrder = "lab_order",
    LabResults = "lab_results",
    Order = "order",
    RxRequest = "rx_request",
    TalkTherapy = "talk_therapy",
    UrgentCare = "urgent_care",
}
/**
 * @export
 * @enum {string}
 */
export enum ConsultVisitStatusEnum {
    Received = "received",
    Unassigned = "unassigned",
    Assigned = "assigned",
    InProgress = "in_progress",
    ReadyToResume = "ready_to_resume",
    ReadyToBook = "ready_to_book",
    Waiting = "waiting",
    ExternalInProgress = "external_in_progress",
    InCall = "in_call",
    Issue = "issue",
    Completed = "completed",
    Rejected = "rejected",
    ReferredOut = "referred_out",
    Canceled = "canceled",
    Scheduled = "scheduled",
    InReview = "in_review",
    WaitingForIntake = "waiting_for_intake",
    WaitingToSchedule = "waiting_to_schedule",
    ClinicianChange = "clinician_change",
    PatientAbandoned = "patient_abandoned",
    VisitRemoved = "visit_removed",
    LabRequested = "lab_requested",
    LabCompleted = "lab_completed",
    LabRejected = "lab_rejected",
    LabSubmitted = "lab_submitted",
    LabAccepted = "lab_accepted",
    LabApproved = "lab_approved",
    LabKitShipped = "lab_kit_shipped",
    LabDenied = "lab_denied",
    LabSampleRejected = "lab_sample_rejected",
    LabProcessingSample = "lab_processing_sample",
    LabSampleReceived = "lab_sample_received",
    LabResultsReady = "lab_results_ready",
    LabResultsNegative = "lab_results_negative",
    LabResultsPositive = "lab_results_positive",
    LabResultsPending = "lab_results_pending",
    LabResultsReceived = "lab_results_received",
    LabResultOutreach = "lab_result_outreach",
    LabResultsNegativeWithFollowUp = "lab_results_negative_with_follow_up",
}
/**
 * @export
 * @enum {string}
 */
export enum ConsultUsStateEnum {
    Al = "AL",
    Ak = "AK",
    As = "AS",
    Az = "AZ",
    Ar = "AR",
    Aa = "AA",
    Ae = "AE",
    Ap = "AP",
    Ca = "CA",
    Co = "CO",
    Ct = "CT",
    De = "DE",
    Dc = "DC",
    Fl = "FL",
    Ga = "GA",
    Gu = "GU",
    Hi = "HI",
    Id = "ID",
    Il = "IL",
    In = "IN",
    Ia = "IA",
    Ks = "KS",
    Ky = "KY",
    La = "LA",
    Me = "ME",
    Md = "MD",
    Ma = "MA",
    Mi = "MI",
    Mn = "MN",
    Ms = "MS",
    Mo = "MO",
    Mt = "MT",
    Ne = "NE",
    Nv = "NV",
    Nh = "NH",
    Nj = "NJ",
    Nm = "NM",
    Ny = "NY",
    Nc = "NC",
    Nd = "ND",
    Mp = "MP",
    Oh = "OH",
    Ok = "OK",
    Or = "OR",
    Pa = "PA",
    Pr = "PR",
    Ri = "RI",
    Sc = "SC",
    Sd = "SD",
    Tn = "TN",
    Tx = "TX",
    Ut = "UT",
    Vt = "VT",
    Vi = "VI",
    Va = "VA",
    Wa = "WA",
    Wv = "WV",
    Wi = "WI",
    Wy = "WY",
}
/**
 * @export
 * @enum {string}
 */
export enum ConsultReasonForVisitEnum {
    Initial = "initial",
    FollowUp = "follow_up",
    SideEffect = "side_effect",
    PharmacyChange = "pharmacy_change",
    MedicationChange = "medication_change",
    AdditionalDocumentation = "additional_documentation",
    AdhocFollowUp = "adhoc_follow_up",
    AdhocSideEffect = "adhoc_side_effect",
    AdhocPharmacyChange = "adhoc_pharmacy_change",
    AdhocPharmacyCall = "adhoc_pharmacy_call",
    AdhocMedicationChange = "adhoc_medication_change",
    AdhocAdditionalDocumentation = "adhoc_additional_documentation",
    LabResult = "lab_result",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface ConsultStatus
 */
export interface ConsultStatus {
    /**
     *
     * @type {string}
     * @memberof ConsultStatus
     */
    visitStatus: string;
}
/**
 *
 * @export
 * @interface ConsultTags
 */
export interface ConsultTags {
    /**
     *
     * @type {string}
     * @memberof ConsultTags
     */
    tag: ConsultTagsTagEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ConsultTagsTagEnum {
    Initial = "initial",
    P1 = "p1",
    P2 = "p2",
}

/**
 *
 * @export
 * @interface CreateLabRequisitionConsult
 */
export interface CreateLabRequisitionConsult {
    /**
     *
     * @type {string}
     * @memberof CreateLabRequisitionConsult
     */
    requestGroupId: string;
}
/**
 *
 * @export
 * @interface Diagnosis
 */
export interface Diagnosis {
    /**
     *
     * @type {string}
     * @memberof Diagnosis
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Diagnosis
     */
    icd10?: string | null;
    /**
     *
     * @type {string}
     * @memberof Diagnosis
     */
    snomed?: string | null;
}
/**
 *
 * @export
 * @interface EndPhoneCallRequest
 */
export interface EndPhoneCallRequest {
    /**
     *
     * @type {string}
     * @memberof EndPhoneCallRequest
     */
    consultGuid: string;
}
/**
 *
 * @export
 * @interface GetPhoneCallStatusRequest
 */
export interface GetPhoneCallStatusRequest {
    /**
     *
     * @type {string}
     * @memberof GetPhoneCallStatusRequest
     */
    consultGuid: string;
    /**
     *
     * @type {string}
     * @memberof GetPhoneCallStatusRequest
     */
    participantIdentity: string;
}
/**
 *
 * @export
 * @interface HangUpPhoneCallRequest
 */
export interface HangUpPhoneCallRequest {
    /**
     *
     * @type {string}
     * @memberof HangUpPhoneCallRequest
     */
    consultGuid: string;
    /**
     *
     * @type {string}
     * @memberof HangUpPhoneCallRequest
     */
    participantIdentity: string;
}
/**
 *
 * @export
 * @interface LabTest
 */
export interface LabTest {
    /**
     *
     * @type {string}
     * @memberof LabTest
     */
    guid: string;
    /**
     * Laboratory ID
     * @type {string}
     * @memberof LabTest
     */
    laboratory: LabTestLaboratoryEnum;
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabTest
     */
    labOrderCode?: string;
    /**
     * Example: Complete Blood Count (CBC) With Differential
     * @type {string}
     * @memberof LabTest
     */
    labOrderName?: string;
    /**
     * Example: 57021-8 for CBC test
     * @type {string}
     * @memberof LabTest
     */
    loinc: string | null;
    /**
     * Example: Test includes Hematocrit; hemoglobin; mean corpuscular volume (MCV); mean corpuscular hemoglobin (MCH); mean corpuscular hemoglobin concentration (MCHC); red cell distribution width (RDW); percentage and absolute differential counts; platelet count (RBC); red cell count; white blood cell count (WBC)
     * @type {string}
     * @memberof LabTest
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LabTest
     */
    cptCodes?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum LabTestLaboratoryEnum {
    AbbottLab = "ABBOTT_LAB",
    Ce = "CE",
    ClinicalReferenceLaboratory = "CLINICAL_REFERENCE_LABORATORY",
    Exceltox = "EXCELTOX",
    GenericLab = "GENERIC_LAB",
    GenomicsInc = "GENOMICS_INC",
    LabCorp = "LAB_CORP",
    MolecularTestingLabs = "MOLECULAR_TESTING_LABS",
    Nucleus = "NUCLEUS",
    Phase = "PHASE",
    Quest = "QUEST",
    TestingLabFacility = "TESTING_LAB_FACILITY",
}

/**
 *
 * @export
 * @interface LimitedIntegrationComments
 */
export interface LimitedIntegrationComments {
    /**
     *
     * @type {string}
     * @memberof LimitedIntegrationComments
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof LimitedIntegrationComments
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof LimitedIntegrationComments
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof LimitedIntegrationComments
     */
    createdBy: string;
}
/**
 *
 * @export
 * @interface PaginatedConsultList
 */
export interface PaginatedConsultList {
    /**
     *
     * @type {string}
     * @memberof PaginatedConsultList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedConsultList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Consult>}
     * @memberof PaginatedConsultList
     */
    results?: Array<Consult>;
}
/**
 *
 * @export
 * @interface Participant
 */
export interface Participant {
    /**
     *
     * @type {string}
     * @memberof Participant
     */
    participantIdentity: string;
    /**
     *
     * @type {string}
     * @memberof Participant
     */
    participantNumber: string;
}
/**
 *
 * @export
 * @interface PastConsult
 */
export interface PastConsult {
    /**
     *
     * @type {string}
     * @memberof PastConsult
     */
    statusSetAt: string;
    /**
     *
     * @type {string}
     * @memberof PastConsult
     */
    interactionMode: string;
    /**
     *
     * @type {boolean}
     * @memberof PastConsult
     */
    isSyncInteraction: boolean;
    /**
     *
     * @type {string}
     * @memberof PastConsult
     */
    careOption: string;
    /**
     *
     * @type {string}
     * @memberof PastConsult
     */
    visitStatus: PastConsultVisitStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PastConsult
     */
    guid?: string;
    /**
     *
     * @type {string}
     * @memberof PastConsult
     */
    clinicianName: string;
    /**
     * The link to the EMR. steadymd://episode/-guid- or steadymd://episode/-guid-/lab/requisition/-guid- or a valid URL for a 3rd Party EMR.
     * @type {string}
     * @memberof PastConsult
     */
    emrUri: string;
    /**
     *
     * @type {string}
     * @memberof PastConsult
     */
    reason: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PastConsultVisitStatusEnum {
    Received = "received",
    Unassigned = "unassigned",
    Assigned = "assigned",
    InProgress = "in_progress",
    ReadyToResume = "ready_to_resume",
    ReadyToBook = "ready_to_book",
    Waiting = "waiting",
    ExternalInProgress = "external_in_progress",
    InCall = "in_call",
    Issue = "issue",
    Completed = "completed",
    Rejected = "rejected",
    ReferredOut = "referred_out",
    Canceled = "canceled",
    Scheduled = "scheduled",
    InReview = "in_review",
    WaitingForIntake = "waiting_for_intake",
    WaitingToSchedule = "waiting_to_schedule",
    ClinicianChange = "clinician_change",
    PatientAbandoned = "patient_abandoned",
    VisitRemoved = "visit_removed",
    LabRequested = "lab_requested",
    LabCompleted = "lab_completed",
    LabRejected = "lab_rejected",
    LabSubmitted = "lab_submitted",
    LabAccepted = "lab_accepted",
    LabApproved = "lab_approved",
    LabKitShipped = "lab_kit_shipped",
    LabDenied = "lab_denied",
    LabSampleRejected = "lab_sample_rejected",
    LabProcessingSample = "lab_processing_sample",
    LabSampleReceived = "lab_sample_received",
    LabResultsReady = "lab_results_ready",
    LabResultsNegative = "lab_results_negative",
    LabResultsPositive = "lab_results_positive",
    LabResultsPending = "lab_results_pending",
    LabResultsReceived = "lab_results_received",
    LabResultOutreach = "lab_result_outreach",
    LabResultsNegativeWithFollowUp = "lab_results_negative_with_follow_up",
}

/**
 *
 * @export
 * @interface PastConsultRequest
 */
export interface PastConsultRequest {
    /**
     *
     * @type {string}
     * @memberof PastConsultRequest
     */
    pastConsultGuid: string;
}
/**
 *
 * @export
 * @interface PastEpisode
 */
export interface PastEpisode {
    /**
     *
     * @type {Array<PastConsult>}
     * @memberof PastEpisode
     */
    consults: Array<PastConsult>;
    /**
     *
     * @type {string}
     * @memberof PastEpisode
     */
    partner: string;
    /**
     *
     * @type {string}
     * @memberof PastEpisode
     */
    program: string;
}
/**
 *
 * @export
 * @interface PastEpisodeRequest
 */
export interface PastEpisodeRequest {
    /**
     *
     * @type {string}
     * @memberof PastEpisodeRequest
     */
    guid: string;
}
/**
 *
 * @export
 * @interface PastEpisodeResponse
 */
export interface PastEpisodeResponse {
    /**
     *
     * @type {Array<PastEpisode>}
     * @memberof PastEpisodeResponse
     */
    episode: Array<PastEpisode>;
}
/**
 *
 * @export
 * @interface PatchedClinician
 */
export interface PatchedClinician {
    /**
     *
     * @type {string}
     * @memberof PatchedClinician
     */
    url?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedClinician
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedClinician
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedClinician
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedClinician
     */
    guid?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedClinician
     */
    licensedIn?: Array<PatchedClinicianLicensedInEnum>;
    /**
     *
     * @type {string}
     * @memberof PatchedClinician
     */
    dosespotId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedClinician
     */
    npiNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedClinician
     */
    headshot?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedClinicianLicensedInEnum {
    Al = "AL",
    Ak = "AK",
    As = "AS",
    Az = "AZ",
    Ar = "AR",
    Aa = "AA",
    Ae = "AE",
    Ap = "AP",
    Ca = "CA",
    Co = "CO",
    Ct = "CT",
    De = "DE",
    Dc = "DC",
    Fl = "FL",
    Ga = "GA",
    Gu = "GU",
    Hi = "HI",
    Id = "ID",
    Il = "IL",
    In = "IN",
    Ia = "IA",
    Ks = "KS",
    Ky = "KY",
    La = "LA",
    Me = "ME",
    Md = "MD",
    Ma = "MA",
    Mi = "MI",
    Mn = "MN",
    Ms = "MS",
    Mo = "MO",
    Mt = "MT",
    Ne = "NE",
    Nv = "NV",
    Nh = "NH",
    Nj = "NJ",
    Nm = "NM",
    Ny = "NY",
    Nc = "NC",
    Nd = "ND",
    Mp = "MP",
    Oh = "OH",
    Ok = "OK",
    Or = "OR",
    Pa = "PA",
    Pr = "PR",
    Ri = "RI",
    Sc = "SC",
    Sd = "SD",
    Tn = "TN",
    Tx = "TX",
    Ut = "UT",
    Vt = "VT",
    Vi = "VI",
    Va = "VA",
    Wa = "WA",
    Wv = "WV",
    Wi = "WI",
    Wy = "WY",
}

/**
 *
 * @export
 * @interface PatchedProgram
 */
export interface PatchedProgram {
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    url?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    guid?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    description?: string;
    /**
     * These appear as a bulleted list in the clinician portal.
     * @type {Array<string>}
     * @memberof PatchedProgram
     */
    details?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    isEnrolled?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    partnerGuid?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    partnerName?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    clinicalProtocolsUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    learningMaterialsUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    emrUrl?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    identityVerificationType?: PatchedProgramIdentityVerificationTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    logo?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof PatchedProgram
     */
    isMessagingEnabled?: boolean;
    /**
     * Optional instructions which will appear in the Consult Detail view in the Clinic. To add a link, use this format: [Link Text](https://www.example.com)
     * @type {string}
     * @memberof PatchedProgram
     */
    clinicianInstructions?: string;
    /**
     * Example: +15551234567 (no spaces, periods or other type of formatting)
     * @type {string}
     * @memberof PatchedProgram
     */
    twilioCallerId?: string | null;
    /**
     *
     * @type {Array<ProgramLabTest>}
     * @memberof PatchedProgram
     */
    labTests?: Array<ProgramLabTest>;
    /**
     *
     * @type {Array<Participant>}
     * @memberof PatchedProgram
     */
    participants?: Array<Participant>;
    /**
     *
     * @type {string}
     * @memberof PatchedProgram
     */
    dosespotClinicId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedProgramIdentityVerificationTypeEnum {
    SelfieAndId = "selfie_and_id",
    Id = "id",
    External = "external",
    SyncSeeId = "sync_see_id",
    SyncVerbal = "sync_verbal",
}

/**
 *
 * @export
 * @interface PhoneCall
 */
export interface PhoneCall {
    /**
     *
     * @type {string}
     * @memberof PhoneCall
     */
    consultGuid: string;
    /**
     *
     * @type {string}
     * @memberof PhoneCall
     */
    fromNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof PhoneCall
     */
    toNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof PhoneCall
     */
    participantIdentity: string;
    /**
     *
     * @type {string}
     * @memberof PhoneCall
     */
    sid: string;
}
/**
 *
 * @export
 * @interface Program
 */
export interface Program {
    /**
     *
     * @type {string}
     * @memberof Program
     */
    url: string;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    description?: string;
    /**
     * These appear as a bulleted list in the clinician portal.
     * @type {Array<string>}
     * @memberof Program
     */
    details?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    isEnrolled: string | null;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    partnerGuid: string;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    partnerName: string;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    clinicalProtocolsUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    learningMaterialsUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    emrUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    identityVerificationType?: ProgramIdentityVerificationTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    logo: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Program
     */
    isMessagingEnabled?: boolean;
    /**
     * Optional instructions which will appear in the Consult Detail view in the Clinic. To add a link, use this format: [Link Text](https://www.example.com)
     * @type {string}
     * @memberof Program
     */
    clinicianInstructions?: string;
    /**
     * Example: +15551234567 (no spaces, periods or other type of formatting)
     * @type {string}
     * @memberof Program
     */
    twilioCallerId?: string | null;
    /**
     *
     * @type {Array<ProgramLabTest>}
     * @memberof Program
     */
    labTests: Array<ProgramLabTest>;
    /**
     *
     * @type {Array<Participant>}
     * @memberof Program
     */
    participants: Array<Participant>;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    dosespotClinicId: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ProgramIdentityVerificationTypeEnum {
    SelfieAndId = "selfie_and_id",
    Id = "id",
    External = "external",
    SyncSeeId = "sync_see_id",
    SyncVerbal = "sync_verbal",
}

/**
 *
 * @export
 * @interface ProgramLabTest
 */
export interface ProgramLabTest {
    /**
     *
     * @type {LabTest}
     * @memberof ProgramLabTest
     */
    labTest: LabTest;
    /**
     * Choices: self, guarantor, patient, or thirdParty
     * @type {Array<string>}
     * @memberof ProgramLabTest
     */
    paymentResponsibilities?: Array<string>;
}
/**
 *
 * @export
 * @interface Reason
 */
export interface Reason {
    /**
     *
     * @type {string}
     * @memberof Reason
     */
    guid?: string;
    /**
     *
     * @type {string}
     * @memberof Reason
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof Reason
     */
    slug?: string | null;
    /**
     *
     * @type {string}
     * @memberof Reason
     */
    status: ReasonStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ReasonStatusEnum {
    Rejected = "rejected",
    ReferredOut = "referred_out",
    Unassigned = "unassigned",
    ClinicianChange = "clinician_change",
    LabRejected = "lab_rejected",
    ReadyToResume = "ready_to_resume",
    Issue = "issue",
}

/**
 *
 * @export
 * @interface SendVideoLink
 */
export interface SendVideoLink {
    /**
     * Method to send the video link.
     * @type {string}
     * @memberof SendVideoLink
     */
    sendMethod: SendVideoLinkSendMethodEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SendVideoLinkSendMethodEnum {
    Sms = "sms",
    MessageComponent = "message_component",
    Both = "both",
}

/**
 * ClinicianApi - axios parameter creator
 * @export
 */
export const ClinicianApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *Mixin to log requests
         * @param {Clinician} clinician
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianAuthenticateCreate: async (clinician: Clinician, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinician' is not null or undefined
            assertParamExists("clinicianAuthenticateCreate", "clinician", clinician);
            const localVarPath = `/clinician-api/clinician/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(clinician, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/clinician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PatchedClinician} [patchedClinician]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianPartialUpdate: async (guid: string, patchedClinician?: PatchedClinician, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("clinicianPartialUpdate", "guid", guid);
            const localVarPath = `/clinician-api/clinician/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patchedClinician, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("clinicianRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/clinician/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianTakeBreakCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/clinician/take_break`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Clinician} clinician
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianUpdate: async (guid: string, clinician: Clinician, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("clinicianUpdate", "guid", guid);
            // verify required parameter 'clinician' is not null or undefined
            assertParamExists("clinicianUpdate", "clinician", clinician);
            const localVarPath = `/clinician-api/clinician/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(clinician, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ClinicianApi - functional programming interface
 * @export
 */
export const ClinicianApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ClinicianApiAxiosParamCreator(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {Clinician} clinician
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicianAuthenticateCreate(
            clinician: Clinician,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinician>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicianAuthenticateCreate(clinician, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicianList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Clinician>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicianList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PatchedClinician} [patchedClinician]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicianPartialUpdate(
            guid: string,
            patchedClinician?: PatchedClinician,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinician>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicianPartialUpdate(guid, patchedClinician, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicianRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinician>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicianRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicianTakeBreakCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinician>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicianTakeBreakCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Clinician} clinician
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicianUpdate(
            guid: string,
            clinician: Clinician,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clinician>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicianUpdate(guid, clinician, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ClinicianApi - factory interface
 * @export
 */
export const ClinicianApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClinicianApiFp(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {Clinician} clinician
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianAuthenticateCreate(clinician: Clinician, options?: any): AxiosPromise<Clinician> {
            return localVarFp.clinicianAuthenticateCreate(clinician, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianList(options?: any): AxiosPromise<Array<Clinician>> {
            return localVarFp.clinicianList(options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PatchedClinician} [patchedClinician]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianPartialUpdate(guid: string, patchedClinician?: PatchedClinician, options?: any): AxiosPromise<Clinician> {
            return localVarFp.clinicianPartialUpdate(guid, patchedClinician, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianRetrieve(guid: string, options?: any): AxiosPromise<Clinician> {
            return localVarFp.clinicianRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianTakeBreakCreate(options?: any): AxiosPromise<Clinician> {
            return localVarFp.clinicianTakeBreakCreate(options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Clinician} clinician
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicianUpdate(guid: string, clinician: Clinician, options?: any): AxiosPromise<Clinician> {
            return localVarFp.clinicianUpdate(guid, clinician, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClinicianApi - object-oriented interface
 * @export
 * @class ClinicianApi
 * @extends {BaseAPI}
 */
export class ClinicianApi extends BaseAPI {
    /**
     *Mixin to log requests
     * @param {Clinician} clinician
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianApi
     */
    public clinicianAuthenticateCreate(clinician: Clinician, options?: AxiosRequestConfig) {
        return ClinicianApiFp(this.configuration)
            .clinicianAuthenticateCreate(clinician, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianApi
     */
    public clinicianList(options?: AxiosRequestConfig) {
        return ClinicianApiFp(this.configuration)
            .clinicianList(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {PatchedClinician} [patchedClinician]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianApi
     */
    public clinicianPartialUpdate(guid: string, patchedClinician?: PatchedClinician, options?: AxiosRequestConfig) {
        return ClinicianApiFp(this.configuration)
            .clinicianPartialUpdate(guid, patchedClinician, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianApi
     */
    public clinicianRetrieve(guid: string, options?: AxiosRequestConfig) {
        return ClinicianApiFp(this.configuration)
            .clinicianRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianApi
     */
    public clinicianTakeBreakCreate(options?: AxiosRequestConfig) {
        return ClinicianApiFp(this.configuration)
            .clinicianTakeBreakCreate(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Clinician} clinician
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianApi
     */
    public clinicianUpdate(guid: string, clinician: Clinician, options?: AxiosRequestConfig) {
        return ClinicianApiFp(this.configuration)
            .clinicianUpdate(guid, clinician, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ConsultApi - axios parameter creator
 * @export
 */
export const ConsultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultAcceptUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultAcceptUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultAcceptUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/accept`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultApproveLabOrderUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultApproveLabOrderUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultApproveLabOrderUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/approve_lab_order`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultClearDueDateUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultClearDueDateUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultClearDueDateUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/clear_due_date`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultCompleteUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultCompleteUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultCompleteUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/complete`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clone existing consult with new chart_addendum consult definition
         * @summary Create chart addendum Consult
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultCreateChartAddendumConsultCreate: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultCreateChartAddendumConsultCreate", "guid", guid);
            const localVarPath = `/clinician-api/consult/{guid}/create_chart_addendum_consult`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultDenyLabOrderUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultDenyLabOrderUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultDenyLabOrderUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/deny_lab_order`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultExternalInProgressUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultExternalInProgressUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultExternalInProgressUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/external_in_progress`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {Array<string>} [consultGuid]
         * @param {boolean} [isAsyncInteraction]
         * @param {Array<string>} [partnerGuid]
         * @param {Array<string>} [programGuid]
         * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetAssignedConsultsRetrieve: async (
            consultGuid?: Array<string>,
            isAsyncInteraction?: boolean,
            partnerGuid?: Array<string>,
            programGuid?: Array<string>,
            reasonForVisit?: Array<
                | "initial"
                | "follow_up"
                | "side_effect"
                | "pharmacy_change"
                | "medication_change"
                | "additional_documentation"
                | "adhoc_follow_up"
                | "adhoc_side_effect"
                | "adhoc_pharmacy_change"
                | "adhoc_pharmacy_call"
                | "adhoc_medication_change"
                | "adhoc_additional_documentation"
                | "lab_result"
            >,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/consult/get_assigned_consults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            if (consultGuid) {
                localVarQueryParameter["consult_guid"] = consultGuid;
            }

            if (isAsyncInteraction !== undefined) {
                localVarQueryParameter["is_async_interaction"] = isAsyncInteraction;
            }

            if (partnerGuid) {
                localVarQueryParameter["partner_guid"] = partnerGuid;
            }

            if (programGuid) {
                localVarQueryParameter["program_guid"] = programGuid;
            }

            if (reasonForVisit) {
                localVarQueryParameter["reason_for_visit"] = reasonForVisit;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {Array<string>} [consultGuid]
         * @param {boolean} [isAsyncInteraction]
         * @param {Array<string>} [partnerGuid]
         * @param {Array<string>} [programGuid]
         * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetAvailableConsultsRetrieve: async (
            consultGuid?: Array<string>,
            isAsyncInteraction?: boolean,
            partnerGuid?: Array<string>,
            programGuid?: Array<string>,
            reasonForVisit?: Array<
                | "initial"
                | "follow_up"
                | "side_effect"
                | "pharmacy_change"
                | "medication_change"
                | "additional_documentation"
                | "adhoc_follow_up"
                | "adhoc_side_effect"
                | "adhoc_pharmacy_change"
                | "adhoc_pharmacy_call"
                | "adhoc_medication_change"
                | "adhoc_additional_documentation"
                | "lab_result"
            >,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/consult/get_available_consults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            if (consultGuid) {
                localVarQueryParameter["consult_guid"] = consultGuid;
            }

            if (isAsyncInteraction !== undefined) {
                localVarQueryParameter["is_async_interaction"] = isAsyncInteraction;
            }

            if (partnerGuid) {
                localVarQueryParameter["partner_guid"] = partnerGuid;
            }

            if (programGuid) {
                localVarQueryParameter["program_guid"] = programGuid;
            }

            if (reasonForVisit) {
                localVarQueryParameter["reason_for_visit"] = reasonForVisit;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetCompletedConsultsRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/consult/get_completed_consults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetNextDueConsultRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/consult/get_next_due_consult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} [consultGuid]
         * @param {boolean} [isAsyncInteraction]
         * @param {Array<string>} [partnerGuid]
         * @param {Array<string>} [programGuid]
         * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetNextUpConsultsRetrieve: async (
            consultGuid?: Array<string>,
            isAsyncInteraction?: boolean,
            partnerGuid?: Array<string>,
            programGuid?: Array<string>,
            reasonForVisit?: Array<
                | "initial"
                | "follow_up"
                | "side_effect"
                | "pharmacy_change"
                | "medication_change"
                | "additional_documentation"
                | "adhoc_follow_up"
                | "adhoc_side_effect"
                | "adhoc_pharmacy_change"
                | "adhoc_pharmacy_call"
                | "adhoc_medication_change"
                | "adhoc_additional_documentation"
                | "lab_result"
            >,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/consult/get_next_up_consults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            if (consultGuid) {
                localVarQueryParameter["consult_guid"] = consultGuid;
            }

            if (isAsyncInteraction !== undefined) {
                localVarQueryParameter["is_async_interaction"] = isAsyncInteraction;
            }

            if (partnerGuid) {
                localVarQueryParameter["partner_guid"] = partnerGuid;
            }

            if (programGuid) {
                localVarQueryParameter["program_guid"] = programGuid;
            }

            if (reasonForVisit) {
                localVarQueryParameter["reason_for_visit"] = reasonForVisit;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetNextUpScheduledConsultsRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/consult/get_next_up_scheduled_consults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} guid
         * @param {CreateLabRequisitionConsult} createLabRequisitionConsult
         * Mixin to log requests
         * @param {string} guid
         * @param {CreateLabRequisitionConsult} createLabRequisitionConsult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultHealthGorillaCreateLabRequisitionConsultCreate: async (
            guid: string,
            createLabRequisitionConsult: CreateLabRequisitionConsult,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultHealthGorillaCreateLabRequisitionConsultCreate", "guid", guid);
            // verify required parameter 'createLabRequisitionConsult' is not null or undefined
            assertParamExists("consultHealthGorillaCreateLabRequisitionConsultCreate", "createLabRequisitionConsult", createLabRequisitionConsult);
            const localVarPath = `/clinician-api/consult/{guid}/health_gorilla/create_lab_requisition_consult`.replace(
                `{${"guid"}}`,
                encodeURIComponent(String(guid))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(createLabRequisitionConsult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultHealthGorillaTokenCreate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultHealthGorillaTokenCreate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultHealthGorillaTokenCreate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/health_gorilla/token`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultInCallUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultInCallUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultInCallUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/in_call`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {number} [cursor] The pagination cursor value.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [visitStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultList: async (cursor?: number, pageSize?: number, visitStatus?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/consult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            if (cursor !== undefined) {
                localVarQueryParameter["cursor"] = cursor;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter["page_size"] = pageSize;
            }

            if (visitStatus !== undefined) {
                localVarQueryParameter["visit_status"] = visitStatus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultReadyToResumeUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultReadyToResumeUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultReadyToResumeUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/ready_to_resume`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultReferUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultReferUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultReferUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/refer`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultRejectUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultRejectUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultRejectUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/reject`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultResetStatusUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultResetStatusUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultResetStatusUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/reset_status`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/consult/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PastConsultRequest} pastConsultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultRetrievePastConsultCreate: async (
            guid: string,
            pastConsultRequest: PastConsultRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultRetrievePastConsultCreate", "guid", guid);
            // verify required parameter 'pastConsultRequest' is not null or undefined
            assertParamExists("consultRetrievePastConsultCreate", "pastConsultRequest", pastConsultRequest);
            const localVarPath = `/clinician-api/consult/{guid}/retrieve_past_consult`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(pastConsultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PastEpisodeRequest} pastEpisodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultRetrievePastConsultsCreate: async (
            guid: string,
            pastEpisodeRequest: PastEpisodeRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultRetrievePastConsultsCreate", "guid", guid);
            // verify required parameter 'pastEpisodeRequest' is not null or undefined
            assertParamExists("consultRetrievePastConsultsCreate", "pastEpisodeRequest", pastEpisodeRequest);
            const localVarPath = `/clinician-api/consult/{guid}/retrieve_past_consults`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(pastEpisodeRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultScheduledUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultScheduledUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultScheduledUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/scheduled`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {SendVideoLink} sendVideoLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultSendVideoLinkCreate: async (guid: string, sendVideoLink: SendVideoLink, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultSendVideoLinkCreate", "guid", guid);
            // verify required parameter 'sendVideoLink' is not null or undefined
            assertParamExists("consultSendVideoLinkCreate", "sendVideoLink", sendVideoLink);
            const localVarPath = `/clinician-api/consult/{guid}/send_video_link`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(sendVideoLink, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a stripped down Consult, only providing the status for use in driving UI side effects.
         * @summary Retrieve Consult Status
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultStatusRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultStatusRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/consult/{guid}/status`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultWaitingUpdate: async (guid: string, consult: Consult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("consultWaitingUpdate", "guid", guid);
            // verify required parameter 'consult' is not null or undefined
            assertParamExists("consultWaitingUpdate", "consult", consult);
            const localVarPath = `/clinician-api/consult/{guid}/waiting`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(consult, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ConsultApi - functional programming interface
 * @export
 */
export const ConsultApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsultApiAxiosParamCreator(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultAcceptUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultAcceptUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultApproveLabOrderUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultApproveLabOrderUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultClearDueDateUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultClearDueDateUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultCompleteUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultCompleteUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Clone existing consult with new chart_addendum consult definition
         * @summary Create chart addendum Consult
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultCreateChartAddendumConsultCreate(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultCreateChartAddendumConsultCreate(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultDenyLabOrderUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultDenyLabOrderUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultExternalInProgressUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultExternalInProgressUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {Array<string>} [consultGuid]
         * @param {boolean} [isAsyncInteraction]
         * @param {Array<string>} [partnerGuid]
         * @param {Array<string>} [programGuid]
         * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultGetAssignedConsultsRetrieve(
            consultGuid?: Array<string>,
            isAsyncInteraction?: boolean,
            partnerGuid?: Array<string>,
            programGuid?: Array<string>,
            reasonForVisit?: Array<
                | "initial"
                | "follow_up"
                | "side_effect"
                | "pharmacy_change"
                | "medication_change"
                | "additional_documentation"
                | "adhoc_follow_up"
                | "adhoc_side_effect"
                | "adhoc_pharmacy_change"
                | "adhoc_pharmacy_call"
                | "adhoc_medication_change"
                | "adhoc_additional_documentation"
                | "lab_result"
            >,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultGetAssignedConsultsRetrieve(
                consultGuid,
                isAsyncInteraction,
                partnerGuid,
                programGuid,
                reasonForVisit,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {Array<string>} [consultGuid]
         * @param {boolean} [isAsyncInteraction]
         * @param {Array<string>} [partnerGuid]
         * @param {Array<string>} [programGuid]
         * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultGetAvailableConsultsRetrieve(
            consultGuid?: Array<string>,
            isAsyncInteraction?: boolean,
            partnerGuid?: Array<string>,
            programGuid?: Array<string>,
            reasonForVisit?: Array<
                | "initial"
                | "follow_up"
                | "side_effect"
                | "pharmacy_change"
                | "medication_change"
                | "additional_documentation"
                | "adhoc_follow_up"
                | "adhoc_side_effect"
                | "adhoc_pharmacy_change"
                | "adhoc_pharmacy_call"
                | "adhoc_medication_change"
                | "adhoc_additional_documentation"
                | "lab_result"
            >,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultGetAvailableConsultsRetrieve(
                consultGuid,
                isAsyncInteraction,
                partnerGuid,
                programGuid,
                reasonForVisit,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultGetCompletedConsultsRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultGetCompletedConsultsRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultGetNextDueConsultRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultGetNextDueConsultRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} [consultGuid]
         * @param {boolean} [isAsyncInteraction]
         * @param {Array<string>} [partnerGuid]
         * @param {Array<string>} [programGuid]
         * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultGetNextUpConsultsRetrieve(
            consultGuid?: Array<string>,
            isAsyncInteraction?: boolean,
            partnerGuid?: Array<string>,
            programGuid?: Array<string>,
            reasonForVisit?: Array<
                | "initial"
                | "follow_up"
                | "side_effect"
                | "pharmacy_change"
                | "medication_change"
                | "additional_documentation"
                | "adhoc_follow_up"
                | "adhoc_side_effect"
                | "adhoc_pharmacy_change"
                | "adhoc_pharmacy_call"
                | "adhoc_medication_change"
                | "adhoc_additional_documentation"
                | "lab_result"
            >,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultGetNextUpConsultsRetrieve(
                consultGuid,
                isAsyncInteraction,
                partnerGuid,
                programGuid,
                reasonForVisit,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultGetNextUpScheduledConsultsRetrieve(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultGetNextUpScheduledConsultsRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} guid
         * @param {CreateLabRequisitionConsult} createLabRequisitionConsult
         * Mixin to log requests
         * @param {string} guid
         * @param {CreateLabRequisitionConsult} createLabRequisitionConsult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultHealthGorillaCreateLabRequisitionConsultCreate(
            guid: string,
            createLabRequisitionConsult: CreateLabRequisitionConsult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultHealthGorillaCreateLabRequisitionConsultCreate(
                guid,
                createLabRequisitionConsult,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultHealthGorillaTokenCreate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultHealthGorillaTokenCreate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultInCallUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultInCallUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {number} [cursor] The pagination cursor value.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [visitStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultList(
            cursor?: number,
            pageSize?: number,
            visitStatus?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedConsultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultList(cursor, pageSize, visitStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultReadyToResumeUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultReadyToResumeUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultReferUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultReferUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultRejectUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultRejectUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultResetStatusUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultResetStatusUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PastConsultRequest} pastConsultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultRetrievePastConsultCreate(
            guid: string,
            pastConsultRequest: PastConsultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultRetrievePastConsultCreate(guid, pastConsultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PastEpisodeRequest} pastEpisodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultRetrievePastConsultsCreate(
            guid: string,
            pastEpisodeRequest: PastEpisodeRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PastEpisodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultRetrievePastConsultsCreate(guid, pastEpisodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultScheduledUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultScheduledUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {SendVideoLink} sendVideoLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultSendVideoLinkCreate(
            guid: string,
            sendVideoLink: SendVideoLink,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultSendVideoLinkCreate(guid, sendVideoLink, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a stripped down Consult, only providing the status for use in driving UI side effects.
         * @summary Retrieve Consult Status
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultStatusRetrieve(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultStatusRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultWaitingUpdate(
            guid: string,
            consult: Consult,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultWaitingUpdate(guid, consult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ConsultApi - factory interface
 * @export
 */
export const ConsultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsultApiFp(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultAcceptUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultAcceptUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultApproveLabOrderUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultApproveLabOrderUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultClearDueDateUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultClearDueDateUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultCompleteUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultCompleteUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         * Clone existing consult with new chart_addendum consult definition
         * @summary Create chart addendum Consult
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultCreateChartAddendumConsultCreate(guid: string, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultCreateChartAddendumConsultCreate(guid, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultDenyLabOrderUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultDenyLabOrderUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultExternalInProgressUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultExternalInProgressUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {Array<string>} [consultGuid]
         * @param {boolean} [isAsyncInteraction]
         * @param {Array<string>} [partnerGuid]
         * @param {Array<string>} [programGuid]
         * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetAssignedConsultsRetrieve(
            consultGuid?: Array<string>,
            isAsyncInteraction?: boolean,
            partnerGuid?: Array<string>,
            programGuid?: Array<string>,
            reasonForVisit?: Array<
                | "initial"
                | "follow_up"
                | "side_effect"
                | "pharmacy_change"
                | "medication_change"
                | "additional_documentation"
                | "adhoc_follow_up"
                | "adhoc_side_effect"
                | "adhoc_pharmacy_change"
                | "adhoc_pharmacy_call"
                | "adhoc_medication_change"
                | "adhoc_additional_documentation"
                | "lab_result"
            >,
            options?: any
        ): AxiosPromise<Consult> {
            return localVarFp
                .consultGetAssignedConsultsRetrieve(consultGuid, isAsyncInteraction, partnerGuid, programGuid, reasonForVisit, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {Array<string>} [consultGuid]
         * @param {boolean} [isAsyncInteraction]
         * @param {Array<string>} [partnerGuid]
         * @param {Array<string>} [programGuid]
         * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetAvailableConsultsRetrieve(
            consultGuid?: Array<string>,
            isAsyncInteraction?: boolean,
            partnerGuid?: Array<string>,
            programGuid?: Array<string>,
            reasonForVisit?: Array<
                | "initial"
                | "follow_up"
                | "side_effect"
                | "pharmacy_change"
                | "medication_change"
                | "additional_documentation"
                | "adhoc_follow_up"
                | "adhoc_side_effect"
                | "adhoc_pharmacy_change"
                | "adhoc_pharmacy_call"
                | "adhoc_medication_change"
                | "adhoc_additional_documentation"
                | "lab_result"
            >,
            options?: any
        ): AxiosPromise<Consult> {
            return localVarFp
                .consultGetAvailableConsultsRetrieve(consultGuid, isAsyncInteraction, partnerGuid, programGuid, reasonForVisit, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetCompletedConsultsRetrieve(options?: any): AxiosPromise<Consult> {
            return localVarFp.consultGetCompletedConsultsRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetNextDueConsultRetrieve(options?: any): AxiosPromise<Consult> {
            return localVarFp.consultGetNextDueConsultRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} [consultGuid]
         * @param {boolean} [isAsyncInteraction]
         * @param {Array<string>} [partnerGuid]
         * @param {Array<string>} [programGuid]
         * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetNextUpConsultsRetrieve(
            consultGuid?: Array<string>,
            isAsyncInteraction?: boolean,
            partnerGuid?: Array<string>,
            programGuid?: Array<string>,
            reasonForVisit?: Array<
                | "initial"
                | "follow_up"
                | "side_effect"
                | "pharmacy_change"
                | "medication_change"
                | "additional_documentation"
                | "adhoc_follow_up"
                | "adhoc_side_effect"
                | "adhoc_pharmacy_change"
                | "adhoc_pharmacy_call"
                | "adhoc_medication_change"
                | "adhoc_additional_documentation"
                | "lab_result"
            >,
            options?: any
        ): AxiosPromise<Consult> {
            return localVarFp
                .consultGetNextUpConsultsRetrieve(consultGuid, isAsyncInteraction, partnerGuid, programGuid, reasonForVisit, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultGetNextUpScheduledConsultsRetrieve(options?: any): AxiosPromise<Consult> {
            return localVarFp.consultGetNextUpScheduledConsultsRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} guid
         * @param {CreateLabRequisitionConsult} createLabRequisitionConsult
         * Mixin to log requests
         * @param {string} guid
         * @param {CreateLabRequisitionConsult} createLabRequisitionConsult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultHealthGorillaCreateLabRequisitionConsultCreate(
            guid: string,
            createLabRequisitionConsult: CreateLabRequisitionConsult,
            options?: any
        ): AxiosPromise<Consult> {
            return localVarFp
                .consultHealthGorillaCreateLabRequisitionConsultCreate(guid, createLabRequisitionConsult, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultHealthGorillaTokenCreate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultHealthGorillaTokenCreate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultInCallUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultInCallUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {number} [cursor] The pagination cursor value.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [visitStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultList(cursor?: number, pageSize?: number, visitStatus?: string, options?: any): AxiosPromise<PaginatedConsultList> {
            return localVarFp.consultList(cursor, pageSize, visitStatus, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultReadyToResumeUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultReadyToResumeUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultReferUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultReferUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultRejectUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultRejectUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultResetStatusUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultResetStatusUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultRetrieve(guid: string, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PastConsultRequest} pastConsultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultRetrievePastConsultCreate(guid: string, pastConsultRequest: PastConsultRequest, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultRetrievePastConsultCreate(guid, pastConsultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PastEpisodeRequest} pastEpisodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultRetrievePastConsultsCreate(guid: string, pastEpisodeRequest: PastEpisodeRequest, options?: any): AxiosPromise<PastEpisodeResponse> {
            return localVarFp.consultRetrievePastConsultsCreate(guid, pastEpisodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultScheduledUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultScheduledUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {SendVideoLink} sendVideoLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultSendVideoLinkCreate(guid: string, sendVideoLink: SendVideoLink, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultSendVideoLinkCreate(guid, sendVideoLink, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a stripped down Consult, only providing the status for use in driving UI side effects.
         * @summary Retrieve Consult Status
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultStatusRetrieve(guid: string, options?: any): AxiosPromise<ConsultStatus> {
            return localVarFp.consultStatusRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Consult} consult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultWaitingUpdate(guid: string, consult: Consult, options?: any): AxiosPromise<Consult> {
            return localVarFp.consultWaitingUpdate(guid, consult, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsultApi - object-oriented interface
 * @export
 * @class ConsultApi
 * @extends {BaseAPI}
 */
export class ConsultApi extends BaseAPI {
    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultAcceptUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultAcceptUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultApproveLabOrderUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultApproveLabOrderUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultClearDueDateUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultClearDueDateUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultCompleteUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultCompleteUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Clone existing consult with new chart_addendum consult definition
     * @summary Create chart addendum Consult
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultCreateChartAddendumConsultCreate(guid: string, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultCreateChartAddendumConsultCreate(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultDenyLabOrderUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultDenyLabOrderUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultExternalInProgressUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultExternalInProgressUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {Array<string>} [consultGuid]
     * @param {boolean} [isAsyncInteraction]
     * @param {Array<string>} [partnerGuid]
     * @param {Array<string>} [programGuid]
     * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultGetAssignedConsultsRetrieve(
        consultGuid?: Array<string>,
        isAsyncInteraction?: boolean,
        partnerGuid?: Array<string>,
        programGuid?: Array<string>,
        reasonForVisit?: Array<
            | "initial"
            | "follow_up"
            | "side_effect"
            | "pharmacy_change"
            | "medication_change"
            | "additional_documentation"
            | "adhoc_follow_up"
            | "adhoc_side_effect"
            | "adhoc_pharmacy_change"
            | "adhoc_pharmacy_call"
            | "adhoc_medication_change"
            | "adhoc_additional_documentation"
            | "lab_result"
        >,
        options?: AxiosRequestConfig
    ) {
        return ConsultApiFp(this.configuration)
            .consultGetAssignedConsultsRetrieve(consultGuid, isAsyncInteraction, partnerGuid, programGuid, reasonForVisit, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {Array<string>} [consultGuid]
     * @param {boolean} [isAsyncInteraction]
     * @param {Array<string>} [partnerGuid]
     * @param {Array<string>} [programGuid]
     * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultGetAvailableConsultsRetrieve(
        consultGuid?: Array<string>,
        isAsyncInteraction?: boolean,
        partnerGuid?: Array<string>,
        programGuid?: Array<string>,
        reasonForVisit?: Array<
            | "initial"
            | "follow_up"
            | "side_effect"
            | "pharmacy_change"
            | "medication_change"
            | "additional_documentation"
            | "adhoc_follow_up"
            | "adhoc_side_effect"
            | "adhoc_pharmacy_change"
            | "adhoc_pharmacy_call"
            | "adhoc_medication_change"
            | "adhoc_additional_documentation"
            | "lab_result"
        >,
        options?: AxiosRequestConfig
    ) {
        return ConsultApiFp(this.configuration)
            .consultGetAvailableConsultsRetrieve(consultGuid, isAsyncInteraction, partnerGuid, programGuid, reasonForVisit, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultGetCompletedConsultsRetrieve(options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultGetCompletedConsultsRetrieve(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultGetNextDueConsultRetrieve(options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultGetNextDueConsultRetrieve(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<string>} [consultGuid]
     * @param {boolean} [isAsyncInteraction]
     * @param {Array<string>} [partnerGuid]
     * @param {Array<string>} [programGuid]
     * @param {Array<'initial' | 'follow_up' | 'side_effect' | 'pharmacy_change' | 'medication_change' | 'additional_documentation' | 'adhoc_follow_up' | 'adhoc_side_effect' | 'adhoc_pharmacy_change' | 'adhoc_pharmacy_call' | 'adhoc_medication_change' | 'adhoc_additional_documentation' | 'lab_result'>} [reasonForVisit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultGetNextUpConsultsRetrieve(
        consultGuid?: Array<string>,
        isAsyncInteraction?: boolean,
        partnerGuid?: Array<string>,
        programGuid?: Array<string>,
        reasonForVisit?: Array<
            | "initial"
            | "follow_up"
            | "side_effect"
            | "pharmacy_change"
            | "medication_change"
            | "additional_documentation"
            | "adhoc_follow_up"
            | "adhoc_side_effect"
            | "adhoc_pharmacy_change"
            | "adhoc_pharmacy_call"
            | "adhoc_medication_change"
            | "adhoc_additional_documentation"
            | "lab_result"
        >,
        options?: AxiosRequestConfig
    ) {
        return ConsultApiFp(this.configuration)
            .consultGetNextUpConsultsRetrieve(consultGuid, isAsyncInteraction, partnerGuid, programGuid, reasonForVisit, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultGetNextUpScheduledConsultsRetrieve(options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultGetNextUpScheduledConsultsRetrieve(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} guid
     * @param {CreateLabRequisitionConsult} createLabRequisitionConsult
     * Mixin to log requests
     * @param {string} guid
     * @param {CreateLabRequisitionConsult} createLabRequisitionConsult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultHealthGorillaCreateLabRequisitionConsultCreate(
        guid: string,
        createLabRequisitionConsult: CreateLabRequisitionConsult,
        options?: AxiosRequestConfig
    ) {
        return ConsultApiFp(this.configuration)
            .consultHealthGorillaCreateLabRequisitionConsultCreate(guid, createLabRequisitionConsult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultHealthGorillaTokenCreate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultHealthGorillaTokenCreate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultInCallUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultInCallUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {number} [cursor] The pagination cursor value.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [visitStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultList(cursor?: number, pageSize?: number, visitStatus?: string, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultList(cursor, pageSize, visitStatus, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultReadyToResumeUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultReadyToResumeUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultReferUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultReferUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultRejectUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultRejectUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultResetStatusUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultResetStatusUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultRetrieve(guid: string, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {PastConsultRequest} pastConsultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultRetrievePastConsultCreate(guid: string, pastConsultRequest: PastConsultRequest, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultRetrievePastConsultCreate(guid, pastConsultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {PastEpisodeRequest} pastEpisodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultRetrievePastConsultsCreate(guid: string, pastEpisodeRequest: PastEpisodeRequest, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultRetrievePastConsultsCreate(guid, pastEpisodeRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultScheduledUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultScheduledUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {SendVideoLink} sendVideoLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultSendVideoLinkCreate(guid: string, sendVideoLink: SendVideoLink, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultSendVideoLinkCreate(guid, sendVideoLink, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a stripped down Consult, only providing the status for use in driving UI side effects.
     * @summary Retrieve Consult Status
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultStatusRetrieve(guid: string, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultStatusRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Consult} consult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultApi
     */
    public consultWaitingUpdate(guid: string, consult: Consult, options?: AxiosRequestConfig) {
        return ConsultApiFp(this.configuration)
            .consultWaitingUpdate(guid, consult, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * DiagnosisApi - axios parameter creator
 * @export
 */
export const DiagnosisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *Mixin to log requests
         * @param {string} [icd10]
         * @param {string} [snomed]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnosisList: async (icd10?: string, snomed?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/diagnosis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            if (icd10 !== undefined) {
                localVarQueryParameter["icd_10"] = icd10;
            }

            if (snomed !== undefined) {
                localVarQueryParameter["snomed"] = snomed;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} icd10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnosisRetrieve: async (icd10: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'icd10' is not null or undefined
            assertParamExists("diagnosisRetrieve", "icd10", icd10);
            const localVarPath = `/clinician-api/diagnosis/{icd10}`.replace(`{${"icd10"}}`, encodeURIComponent(String(icd10)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * DiagnosisApi - functional programming interface
 * @export
 */
export const DiagnosisApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DiagnosisApiAxiosParamCreator(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {string} [icd10]
         * @param {string} [snomed]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnosisList(
            icd10?: string,
            snomed?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Diagnosis>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.diagnosisList(icd10, snomed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} icd10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnosisRetrieve(icd10: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Diagnosis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.diagnosisRetrieve(icd10, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * DiagnosisApi - factory interface
 * @export
 */
export const DiagnosisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiagnosisApiFp(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {string} [icd10]
         * @param {string} [snomed]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnosisList(icd10?: string, snomed?: string, options?: any): AxiosPromise<Array<Diagnosis>> {
            return localVarFp.diagnosisList(icd10, snomed, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} icd10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnosisRetrieve(icd10: string, options?: any): AxiosPromise<Diagnosis> {
            return localVarFp.diagnosisRetrieve(icd10, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosisApi - object-oriented interface
 * @export
 * @class DiagnosisApi
 * @extends {BaseAPI}
 */
export class DiagnosisApi extends BaseAPI {
    /**
     *Mixin to log requests
     * @param {string} [icd10]
     * @param {string} [snomed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosisApi
     */
    public diagnosisList(icd10?: string, snomed?: string, options?: AxiosRequestConfig) {
        return DiagnosisApiFp(this.configuration)
            .diagnosisList(icd10, snomed, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} icd10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosisApi
     */
    public diagnosisRetrieve(icd10: string, options?: AxiosRequestConfig) {
        return DiagnosisApiFp(this.configuration)
            .diagnosisRetrieve(icd10, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * PhonecallApi - axios parameter creator
 * @export
 */
export const PhonecallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *Mixin to log requests
         * @param {ConnectPhoneCallRequest} connectPhoneCallRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallConnectPhoneCallCreate: async (connectPhoneCallRequest: ConnectPhoneCallRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectPhoneCallRequest' is not null or undefined
            assertParamExists("phonecallConnectPhoneCallCreate", "connectPhoneCallRequest", connectPhoneCallRequest);
            const localVarPath = `/clinician-api/phonecall/connect_phone_call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectPhoneCallRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {EndPhoneCallRequest} endPhoneCallRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallEndCallCreate: async (endPhoneCallRequest: EndPhoneCallRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'endPhoneCallRequest' is not null or undefined
            assertParamExists("phonecallEndCallCreate", "endPhoneCallRequest", endPhoneCallRequest);
            const localVarPath = `/clinician-api/phonecall/end_call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(endPhoneCallRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {GetPhoneCallStatusRequest} getPhoneCallStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallGetPhoneCallStatusCreate: async (
            getPhoneCallStatusRequest: GetPhoneCallStatusRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getPhoneCallStatusRequest' is not null or undefined
            assertParamExists("phonecallGetPhoneCallStatusCreate", "getPhoneCallStatusRequest", getPhoneCallStatusRequest);
            const localVarPath = `/clinician-api/phonecall/get_phone_call_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(getPhoneCallStatusRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {HangUpPhoneCallRequest} hangUpPhoneCallRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallHangUpCreate: async (hangUpPhoneCallRequest: HangUpPhoneCallRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hangUpPhoneCallRequest' is not null or undefined
            assertParamExists("phonecallHangUpCreate", "hangUpPhoneCallRequest", hangUpPhoneCallRequest);
            const localVarPath = `/clinician-api/phonecall/hang_up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(hangUpPhoneCallRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} consultGuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallRetrieve: async (consultGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultGuid' is not null or undefined
            assertParamExists("phonecallRetrieve", "consultGuid", consultGuid);
            const localVarPath = `/clinician-api/phonecall/{consult_guid}`.replace(`{${"consult_guid"}}`, encodeURIComponent(String(consultGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PhonecallApi - functional programming interface
 * @export
 */
export const PhonecallApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PhonecallApiAxiosParamCreator(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {ConnectPhoneCallRequest} connectPhoneCallRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phonecallConnectPhoneCallCreate(
            connectPhoneCallRequest: ConnectPhoneCallRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneCall>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phonecallConnectPhoneCallCreate(connectPhoneCallRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {EndPhoneCallRequest} endPhoneCallRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phonecallEndCallCreate(
            endPhoneCallRequest: EndPhoneCallRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneCall>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phonecallEndCallCreate(endPhoneCallRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {GetPhoneCallStatusRequest} getPhoneCallStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phonecallGetPhoneCallStatusCreate(
            getPhoneCallStatusRequest: GetPhoneCallStatusRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phonecallGetPhoneCallStatusCreate(getPhoneCallStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {HangUpPhoneCallRequest} hangUpPhoneCallRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phonecallHangUpCreate(
            hangUpPhoneCallRequest: HangUpPhoneCallRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneCall>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phonecallHangUpCreate(hangUpPhoneCallRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} consultGuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phonecallRetrieve(
            consultGuid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneCall>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phonecallRetrieve(consultGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * PhonecallApi - factory interface
 * @export
 */
export const PhonecallApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhonecallApiFp(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {ConnectPhoneCallRequest} connectPhoneCallRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallConnectPhoneCallCreate(connectPhoneCallRequest: ConnectPhoneCallRequest, options?: any): AxiosPromise<PhoneCall> {
            return localVarFp.phonecallConnectPhoneCallCreate(connectPhoneCallRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {EndPhoneCallRequest} endPhoneCallRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallEndCallCreate(endPhoneCallRequest: EndPhoneCallRequest, options?: any): AxiosPromise<PhoneCall> {
            return localVarFp.phonecallEndCallCreate(endPhoneCallRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {GetPhoneCallStatusRequest} getPhoneCallStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallGetPhoneCallStatusCreate(getPhoneCallStatusRequest: GetPhoneCallStatusRequest, options?: any): AxiosPromise<{ [key: string]: any }> {
            return localVarFp.phonecallGetPhoneCallStatusCreate(getPhoneCallStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {HangUpPhoneCallRequest} hangUpPhoneCallRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallHangUpCreate(hangUpPhoneCallRequest: HangUpPhoneCallRequest, options?: any): AxiosPromise<PhoneCall> {
            return localVarFp.phonecallHangUpCreate(hangUpPhoneCallRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} consultGuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonecallRetrieve(consultGuid: string, options?: any): AxiosPromise<PhoneCall> {
            return localVarFp.phonecallRetrieve(consultGuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhonecallApi - object-oriented interface
 * @export
 * @class PhonecallApi
 * @extends {BaseAPI}
 */
export class PhonecallApi extends BaseAPI {
    /**
     *Mixin to log requests
     * @param {ConnectPhoneCallRequest} connectPhoneCallRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonecallApi
     */
    public phonecallConnectPhoneCallCreate(connectPhoneCallRequest: ConnectPhoneCallRequest, options?: AxiosRequestConfig) {
        return PhonecallApiFp(this.configuration)
            .phonecallConnectPhoneCallCreate(connectPhoneCallRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {EndPhoneCallRequest} endPhoneCallRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonecallApi
     */
    public phonecallEndCallCreate(endPhoneCallRequest: EndPhoneCallRequest, options?: AxiosRequestConfig) {
        return PhonecallApiFp(this.configuration)
            .phonecallEndCallCreate(endPhoneCallRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {GetPhoneCallStatusRequest} getPhoneCallStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonecallApi
     */
    public phonecallGetPhoneCallStatusCreate(getPhoneCallStatusRequest: GetPhoneCallStatusRequest, options?: AxiosRequestConfig) {
        return PhonecallApiFp(this.configuration)
            .phonecallGetPhoneCallStatusCreate(getPhoneCallStatusRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {HangUpPhoneCallRequest} hangUpPhoneCallRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonecallApi
     */
    public phonecallHangUpCreate(hangUpPhoneCallRequest: HangUpPhoneCallRequest, options?: AxiosRequestConfig) {
        return PhonecallApiFp(this.configuration)
            .phonecallHangUpCreate(hangUpPhoneCallRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} consultGuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonecallApi
     */
    public phonecallRetrieve(consultGuid: string, options?: AxiosRequestConfig) {
        return PhonecallApiFp(this.configuration)
            .phonecallRetrieve(consultGuid, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ProgramApi - axios parameter creator
 * @export
 */
export const ProgramApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *Mixin to log requests
         * @param {Program} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programCreate: async (program: Program, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists("programCreate", "program", program);
            const localVarPath = `/clinician-api/program`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(program, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programDestroy: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("programDestroy", "guid", guid);
            const localVarPath = `/clinician-api/program/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} [displayName]
         * @param {string} [clinicianGuid]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programList: async (displayName?: string, clinicianGuid?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/program`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            if (displayName !== undefined) {
                localVarQueryParameter["_display_name"] = displayName;
            }

            if (clinicianGuid !== undefined) {
                localVarQueryParameter["clinician__guid"] = clinicianGuid;
            }

            if (name !== undefined) {
                localVarQueryParameter["name"] = name;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PatchedProgram} [patchedProgram]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programPartialUpdate: async (guid: string, patchedProgram?: PatchedProgram, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("programPartialUpdate", "guid", guid);
            const localVarPath = `/clinician-api/program/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProgram, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("programRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/program/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Program} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programUpdate: async (guid: string, program: Program, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("programUpdate", "guid", guid);
            // verify required parameter 'program' is not null or undefined
            assertParamExists("programUpdate", "program", program);
            const localVarPath = `/clinician-api/program/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required



            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(program, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ProgramApi - functional programming interface
 * @export
 */
export const ProgramApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ProgramApiAxiosParamCreator(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {Program} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async programCreate(program: Program, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.programCreate(program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async programDestroy(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.programDestroy(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} [displayName]
         * @param {string} [clinicianGuid]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async programList(
            displayName?: string,
            clinicianGuid?: string,
            name?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Program>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.programList(displayName, clinicianGuid, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PatchedProgram} [patchedProgram]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async programPartialUpdate(
            guid: string,
            patchedProgram?: PatchedProgram,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.programPartialUpdate(guid, patchedProgram, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async programRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.programRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Program} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async programUpdate(
            guid: string,
            program: Program,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.programUpdate(guid, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ProgramApi - factory interface
 * @export
 */
export const ProgramApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProgramApiFp(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {Program} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programCreate(program: Program, options?: any): AxiosPromise<Program> {
            return localVarFp.programCreate(program, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programDestroy(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.programDestroy(guid, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} [displayName]
         * @param {string} [clinicianGuid]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programList(displayName?: string, clinicianGuid?: string, name?: string, options?: any): AxiosPromise<Array<Program>> {
            return localVarFp.programList(displayName, clinicianGuid, name, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {PatchedProgram} [patchedProgram]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programPartialUpdate(guid: string, patchedProgram?: PatchedProgram, options?: any): AxiosPromise<Program> {
            return localVarFp.programPartialUpdate(guid, patchedProgram, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programRetrieve(guid: string, options?: any): AxiosPromise<Program> {
            return localVarFp.programRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} guid
         * @param {Program} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        programUpdate(guid: string, program: Program, options?: any): AxiosPromise<Program> {
            return localVarFp.programUpdate(guid, program, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProgramApi - object-oriented interface
 * @export
 * @class ProgramApi
 * @extends {BaseAPI}
 */
export class ProgramApi extends BaseAPI {
    /**
     *Mixin to log requests
     * @param {Program} program
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramApi
     */
    public programCreate(program: Program, options?: AxiosRequestConfig) {
        return ProgramApiFp(this.configuration)
            .programCreate(program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramApi
     */
    public programDestroy(guid: string, options?: AxiosRequestConfig) {
        return ProgramApiFp(this.configuration)
            .programDestroy(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} [displayName]
     * @param {string} [clinicianGuid]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramApi
     */
    public programList(displayName?: string, clinicianGuid?: string, name?: string, options?: AxiosRequestConfig) {
        return ProgramApiFp(this.configuration)
            .programList(displayName, clinicianGuid, name, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {PatchedProgram} [patchedProgram]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramApi
     */
    public programPartialUpdate(guid: string, patchedProgram?: PatchedProgram, options?: AxiosRequestConfig) {
        return ProgramApiFp(this.configuration)
            .programPartialUpdate(guid, patchedProgram, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramApi
     */
    public programRetrieve(guid: string, options?: AxiosRequestConfig) {
        return ProgramApiFp(this.configuration)
            .programRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} guid
     * @param {Program} program
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramApi
     */
    public programUpdate(guid: string, program: Program, options?: AxiosRequestConfig) {
        return ProgramApiFp(this.configuration)
            .programUpdate(guid, program, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ReasonApi - axios parameter creator
 * @export
 */
export const ReasonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *Mixin to log requests
         * @param {'clinician_change' | 'issue' | 'lab_rejected' | 'ready_to_resume' | 'referred_out' | 'rejected' | 'unassigned'} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reasonList: async (
            status?: "clinician_change" | "issue" | "lab_rejected" | "ready_to_resume" | "referred_out" | "rejected" | "unassigned",
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            if (status !== undefined) {
                localVarQueryParameter["status"] = status;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *Mixin to log requests
         * @param {string} text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reasonRetrieve: async (text: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            assertParamExists("reasonRetrieve", "text", text);
            const localVarPath = `/clinician-api/reason/{text}`.replace(`{${"text"}}`, encodeURIComponent(String(text)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ReasonApi - functional programming interface
 * @export
 */
export const ReasonApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ReasonApiAxiosParamCreator(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {'clinician_change' | 'issue' | 'lab_rejected' | 'ready_to_resume' | 'referred_out' | 'rejected' | 'unassigned'} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reasonList(
            status?: "clinician_change" | "issue" | "lab_rejected" | "ready_to_resume" | "referred_out" | "rejected" | "unassigned",
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reason>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reasonList(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *Mixin to log requests
         * @param {string} text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reasonRetrieve(text: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reason>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reasonRetrieve(text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ReasonApi - factory interface
 * @export
 */
export const ReasonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReasonApiFp(configuration);
    return {
        /**
         *Mixin to log requests
         * @param {'clinician_change' | 'issue' | 'lab_rejected' | 'ready_to_resume' | 'referred_out' | 'rejected' | 'unassigned'} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reasonList(
            status?: "clinician_change" | "issue" | "lab_rejected" | "ready_to_resume" | "referred_out" | "rejected" | "unassigned",
            options?: any
        ): AxiosPromise<Array<Reason>> {
            return localVarFp.reasonList(status, options).then((request) => request(axios, basePath));
        },
        /**
         *Mixin to log requests
         * @param {string} text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reasonRetrieve(text: string, options?: any): AxiosPromise<Reason> {
            return localVarFp.reasonRetrieve(text, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReasonApi - object-oriented interface
 * @export
 * @class ReasonApi
 * @extends {BaseAPI}
 */
export class ReasonApi extends BaseAPI {
    /**
     *Mixin to log requests
     * @param {'clinician_change' | 'issue' | 'lab_rejected' | 'ready_to_resume' | 'referred_out' | 'rejected' | 'unassigned'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReasonApi
     */
    public reasonList(
        status?: "clinician_change" | "issue" | "lab_rejected" | "ready_to_resume" | "referred_out" | "rejected" | "unassigned",
        options?: AxiosRequestConfig
    ) {
        return ReasonApiFp(this.configuration)
            .reasonList(status, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *Mixin to log requests
     * @param {string} text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReasonApi
     */
    public reasonRetrieve(text: string, options?: AxiosRequestConfig) {
        return ReasonApiFp(this.configuration)
            .reasonRetrieve(text, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
